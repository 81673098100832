import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { useAuthContext } from '../../context/AuthContext'
import { Loading } from '../../components'

import { getUrlParamsWithSlug } from '../../utils/enrollmentHelpers'

const StorePage = ({ params }) => {
  const { handleSetUserState } = useAuthContext()
  const location = useLocation()
  useEffect(() => {
    const getEnroller = async () => {
      let enrollerData = await getUrlParamsWithSlug(
        location.search,
        params.name
      )
      if (!enrollerData.ambassadorName) {
        alert(`Invalid store name: ${params.name}`)
        navigate('/')
      } else {
        // CLEAR EXISTING ENROLLER DATA IN LOCAL STORAGE IF ANY
        localStorage.removeItem('enrollerData')
        localStorage.removeItem('persistEnrollerData')
        // SET NEW/UPDATED ENROLLER DATA IN LOCAL STORAGE
        localStorage.setItem('enrollerData', JSON.stringify(enrollerData))

        handleSetUserState({
          isReferral: true,
          referralData: enrollerData,
          shouldAddPcFee: enrollerData.type === 'pc',
        })

        if (enrollerData.type === 'ambassador') {
          navigate('/login')
        } else navigate('/')
      }
    }

    getEnroller()
  }, [])
  return (
    <>
      <Loading loading={true} message={`Loading store ${params.name}...`} />
    </>
  )
}

export default StorePage
